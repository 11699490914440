<template>
  <div class="login">
    <!--    <van-row class="m-header">-->
    <!--      <van-col span="24">-->
    <!--&lt;!&ndash;        <van-icon class="m-header-icon" name="arrow-left"/>&ndash;&gt;-->
    <!--        <span>登录</span>-->
    <!--      </van-col>-->
    <!--    </van-row>-->
    <div class="loginMain">
      <!--      <p v-if="type" class="title">验证码登录</p>-->
      <!--      <p v-else class="title">密码登录</p>-->
      <van-form>
        <van-field
            label="手机号"
            v-model="phone"
            required
            :rules="[{ required: true, message: '' }]"
            maxlength="11"
            placeholder="请输入手机号"
            type="tel"
        />
        <van-field
            label="验证码"
            v-model="code"
            required
            :rules="[{ required: true, message: '' }]"
            placeholder=""
        >
          <template #button>
            <van-button class="fieldBtn" type="info" @click="toGetCode" :disabled="!activeCode">
              {{ count ? '重新获取(' + count + 's)' : '获取验证码' }}
            </van-button>
            <!--          <span :class="{'activeBtn': activeCode}" class="fieldBtn"-->
            <!--                @click="toGetCode">{{ count ? '重新获取(' + count + 's)' : '获取验证码' }}</span>-->
          </template>
        </van-field>
        <van-field
            v-if="showNameOrg"
            label="姓名"
            v-model="userName"
            required
            :rules="[{ required: true, message: '' }]"
            maxlength="10"
            placeholder="请输入姓名"
        />
        <van-field
            v-if="showNameOrg"
            label="企业名称"
            v-model="orgName"
            required
            :rules="[{ required: true, message: '' }]"
            maxlength="20"
            placeholder="请输入企业名称"
        />
        <div class="orgTip" v-if="showNameOrg">未查找到您的企业信息，请直接填入您的企业</div>
        <div class="bottomBtn">
          <van-button block native-type="submit" round type="info" @click="onSubmit">登录</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router'
import {Toast} from 'vant';
import {devType, verifyPhone} from '@/utils/toolsValidate';
import {login, sendSmsVerifyCode} from '@/api/login'

const {Session} = require("../../utils/storage");

export default {
  name: "register",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      disabled: true,
      phone: '',
      code: '',
      userName: '',
      orgName: '',
      activeCode: false,
      checked: false,
      count: '',
      timer: null,
      type: true,
      showNameOrg: false,
      endInquiryId: '',
      url: ''
    })
    const toAgreement = () => {
      router.push("/UserAgreement")
    }
    const toGetCode = () => {
      if (!state.activeCode) return
      console.log(123)
      if (!verifyPhone(state.phone)) {
        return Toast('请输入正确的手机号码');
      } else {
        const param = {
          countryNumber: '86',
          // bizType: 'VERIFICATION_CODE',
          phone: state.phone
        }
        console.log(param, state.timer)
        sendSmsVerifyCode(param).then((res) => {
          console.log(res)
          const TIME_COUNT = 60;
          state.showNameOrg = res.data ? false : true
          if (!state.timer) {
            state.count = TIME_COUNT;
            state.activeCode = false
            state.timer = setInterval(() => {
              if (state.count > 0 && state.count <= TIME_COUNT) {
                state.count--;
                state.activeCode = false
              } else {
                state.activeCode = true
                clearInterval(state.timer);
                state.timer = null;
              }
            }, 1000)
          }
        })
      }
    }
    // const reset = () => {
    //   state.disabled= true
    //   state.phone= ''
    //   state.code= ''
    //   state.userName= ''
    //   state.orgName=''
    //   state.activeCode= false
    //   state.checked= false
    //   state.count= ''
    //   state.timer= null
    //   state.type= true
    //   state.showNameOrg = false
    // }
    const onSubmit = () => {
      console.log(123)
      if (!verifyPhone(state.phone)) {
        return Toast('请输入正确的手机号码');
      } else if (!state.code) {
        return Toast('请输入验证码');
      } else if (state.showNameOrg) {
        if (!state.userName) {
          return Toast('请输入姓名');
        } else if (!state.orgName) {
          return Toast('请输入企业名称');
        } else {
          goLogin()
        }
      } else {
        goLogin()
      }
    }
    const goLogin = () => {
      const param = {
        devType: devType(),
        phone: state.phone,
        smsCode: state.code,
        userName: state.userName,
        orgName: state.orgName
      }
      console.log(param)
      login(param).then(res => {
        Session.set('token', res.data);
        // if (localStorage.getItem('redirectUrl')) {
        //   window.location.href = localStorage.getItem('redirectUrl')
        //   localStorage.removeItem('redirectUrl')
        // } else
        if (state.endInquiryId) {
          console.log(111511111515)
          router.push({
            path: state.url,
            query: {
              endInquiryId: state.endInquiryId,
            }
          })
          // reset()
        } else {
          router.push({
            path: '/quotationList',
          })
          // reset()
          // window.location.href = '/quotationList'
        }
      })
    }
    watch(() => state.phone, (newVal) => {
      if (newVal && newVal.length == 11) {
        state.activeCode = true
        // if (state.password) {
        //   state.disabled = false
        // }
      } else {
        state.activeCode = false
        state.disabled = true
      }
    })
    watch(() => state.code, (newVal) => {
      if (newVal) {
        if (state.phone) {
          state.disabled = false
        }
      } else {
        state.disabled = true
      }
    })
    // watch(() => state.password, (newVal) => {
    //   if(newVal) {
    //     if (state.phone) {
    //       state.disabled = false
    //     }
    //   } else {
    //     state.disabled = true
    //   }
    // })
    onMounted(() => {
      console.log(route)
      window.document.title = '招采公告';
      state.endInquiryId = route.query.endInquiryId
      state.url = route.query.url;
    })
    return {
      onSubmit,
      toGetCode,
      toAgreement,
      ...toRefs(state),
    }
  }
}
</script>

<style lang="less" scoped>

.login {
  width: 100%;
  height: 100vh;
  background: url("../../assets/login/bg@2x.png") no-repeat;
  background-size: 100%;
  position: relative;
}

.loginMain {
  position: absolute;
  top: 50%;
  padding: 0 0.32rem;
  margin-bottom: 0.42rem;
  //background: #FFFFFF;
  //height: 100vh;
  .van-cell {
    font-size: 0.14rem !important;
  }

  .van-field {
    //height: 0.8rem;
    margin: 0 0 0.08rem 0;
    background: 0;
    //border-radius: 0.4rem;
  }

  .fieldBtn {
    min-width: 0.91rem;
    height: 0.32rem;
    background: #3967A2;
    border-radius: 0.05rem;
    opacity: 0.8;
    font-size: 0.14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0.08rem;
  }

  .activeBtn {
    color: #41E3AF;
  }

  .van-toast__text {
    font-size: .14rem;
  }

  .orgTip {
    font-size: 0.12rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 0.17rem;
    width: 100%;
    text-align: center;
    margin-top: 0.05rem;
  }

  .bottomBtn {
    position: relative;
    margin-top: .18rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .forget {
      position: absolute;
      right: 0;
      top: -.5rem;
      font-size: 0.24rem;
      font-weight: 400;
      color: #898989;
      line-height: 0.24rem;
    }

    .van-button {
      width: 2.47rem;
      height: 0.4rem;
      background: #3967A2;
      border-radius: 0.06rem;
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
    }

    .van-button--disabled {
      opacity: 1 !important;
      background: #3967A2;
    }

    .bottom {
      margin-top: .3rem;
      font-size: 0.24rem;
      font-weight: 400;
      color: #E4E4E4;
      line-height: 0.33rem;

      span {
        font-size: 0.24rem;
        font-weight: 400;
        color: #666666;
        line-height: 0.33rem;
      }

      span:first-child {
        margin-right: .2rem;
      }

      span:last-child {
        margin-left: .2rem;
      }
    }
  }

}
</style>
